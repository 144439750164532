import React, { Component } from 'react'
import './styles/Metronome.css'
import * as metronome from '../config/config.js'

class Metronome extends Component {  
  constructor(props) {
    super()
    this.state = {
      BPM: 148,
      isPlaying: false,
      subdivision: 0,
      timeSignature: 4,
      lastTimeSignature: 4,
      accentPattern: props.accentPattern,
      currentPlaylist: null,
      numCurrentPlaylist: 0,
      currentBar: 0,
      totalBars: 0,
      playingPlaylist: false
    }
    this.updateBPM = this.updateBPM.bind(this)
    this.updateTimeSignature = this.updateTimeSignature.bind(this)
    this.updateSubdivision = this.updateSubdivision.bind(this)
    this.scheduler = this.scheduler.bind(this)
    this.scheduleNote = this.scheduleNote.bind(this)
    this.nextNote = this.nextNote.bind(this)
    this.play = this.play.bind(this)
    this.startPlaylist = this.startPlaylist.bind(this)
    this.resetAccentPattern = props.resetAccentPattern
  }

  componentDidMount() {
    metronome.config.timerWorker.onmessage = function(e) {
      if (e.data === "tick")
          this.scheduler()
      else
          console.log("message: " + e.data)
    }.bind(this)
    metronome.config.timerWorker.postMessage({"interval": metronome.config.lookahead})
  }

  componentWillReceiveProps(nextProps) {
    this.setState({accentPattern: nextProps.accentPattern})
  }

  constructTapOff(tempo) {
    this.resetAccentPattern([true, false, false, false])
    return [
      {
        tempo: tempo,
        numBars: 2,
        accentPattern: [true, false, false, false],
        timeSignature: 4,
        subdivision: 0
      },
      {
        tempo: tempo,
        numBars: 1,
        accentPattern: [true, false, true, false],
        timeSignature: 4,
        subdivision: 0
      },
      {
        tempo: tempo,
        numBars: 1,
        accentPattern: [true, true, true, true],
        timeSignature: 4,
        subdivision: 0
      },
    ]
  }

  startPlaylist(newPlaylist, playlistIndex) {
    var playlistChunk = newPlaylist.slice(playlistIndex)
    var tapOff = this.constructTapOff(playlistChunk[0].tempo)
    var playlistWithTapOff = tapOff.concat(playlistChunk)

    if (this.state.currentPlaylist !== playlistWithTapOff) {
      this.setState({
        currentPlaylist: playlistWithTapOff,
        numCurrentPlaylist: 0
      })
    }

    this.setState({
      BPM: playlistWithTapOff[0].tempo,
      subdivision: playlistWithTapOff[0].subdivision,
      timeSignature: 4,
      lastTimeSignature: 4,
      totalBars: playlistWithTapOff[0].numBars,
      currentBar: 0
    })
    this.play()
  }

  scheduler() {
    // while there are notes that will need to play before the next interval, schedule them and advance the pointer.
    while (metronome.config.nextNoteTime < metronome.config.audioContext.currentTime + metronome.config.scheduleAheadTime ) {
        this.scheduleNote( metronome.config.current16thNote, metronome.config.nextNoteTime )
        this.nextNote()
    }
  }

  scheduleNote( beatNumber, time ) {
    // push the note on the queue, even if we're not playing.
    metronome.config.notesInQueue.push( { note: beatNumber, time: time } )
    if ( (this.state.subdivision === 1) && (beatNumber % 6)) return// current 8th note 
    else if ( (this.state.subdivision === 2) && (beatNumber % 3)) return // current 16th note
    else if ( (this.state.subdivision === 0) && (beatNumber % 12)) return  // current quarter note
    else if ( (this.state.subdivision === 3) && (beatNumber % 4)) return
  
    // create an oscillator
    var osc = metronome.config.audioContext.createOscillator()
    osc.connect( metronome.config.audioContext.destination )
    if (this.state.subdivision !== 3) {
        if (beatNumber % 12 === 0 && this.state.accentPattern[beatNumber/12])    // beat 0 == high pitch
            osc.frequency.value = 880.0
        else if (beatNumber % 4 === 0 )    // quarter notes = medium pitch
            osc.frequency.value = 440.0
        else                        // other 16th notes = low pitch
            osc.frequency.value = 220.0
    } else { // triple meter
        if (beatNumber === 0)    // beat 0 == high pitch
            osc.frequency.value = 880.0
        else if (beatNumber % 3 === 0 )    // quarter notes = medium pitch
            osc.frequency.value = 440.0
        else                        // other 16th notes = low pitch
            osc.frequency.value = 220.0
    }
  
    osc.start( time )
    osc.stop( time + metronome.config.noteLength )
  }
  
  nextNote() {
    // Advance current note and time by a 16th note...
    var secondsPerBeat = 60.0 / this.state.BPM  // Notice this picks up the CURRENT 
                                          // tempo value to calculate beat length.
    metronome.config.nextNoteTime += 0.083333333333333 * secondsPerBeat    // Add beat length to last beat time
  
    metronome.config.current16thNote++    // Advance the beat number, wrap to zero
    if (metronome.config.current16thNote === (12 * this.state.lastTimeSignature)) {
        this.setState({lastTimeSignature: this.state.timeSignature})

        if (this.state.currentBar+1 === this.state.totalBars) {
          if (this.state.numCurrentPlaylist+1 < this.state.currentPlaylist.length) {
            this.setState({numCurrentPlaylist: this.state.numCurrentPlaylist + 1})
            this.resetAccentPattern(this.state.currentPlaylist[this.state.numCurrentPlaylist].accentPattern)
            this.setState({
              BPM: this.state.currentPlaylist[this.state.numCurrentPlaylist].tempo,
              subdivision: this.state.currentPlaylist[this.state.numCurrentPlaylist].subdivision,
              timeSignature: this.state.currentPlaylist[this.state.numCurrentPlaylist].timeSignature,
              lastTimeSignature: this.state.currentPlaylist[this.state.numCurrentPlaylist].timeSignature,
              totalBars: this.state.currentPlaylist[this.state.numCurrentPlaylist].numBars,
              currentBar: 0
            })
          } else {
            this.play()
          }
        } else {
          this.setState({currentBar: this.state.currentBar + 1})
        }
        
        metronome.config.current16thNote = 0

    }
  }

  updateBPM(event) {
    let newBPM = event.target.value
    this.setState({BPM: newBPM})
  }

  updateTimeSignature(event) {
    let newTimeSignature = event.target.selectedIndex + 2
    this.setState({timeSignature: newTimeSignature})
    if (!this.state.isPlaying) {
      this.setState({lastTimeSignature: newTimeSignature})
      metronome.config.current16thNote = 0
      metronome.config.timerWorker.postMessage("stop");
    }
    switch (newTimeSignature) {
      case 2:
        this.resetAccentPattern([true, false])
        break
      case 3:
        this.resetAccentPattern([true, false, false])
        break
      case 4:
        this.resetAccentPattern([true, false, false, false])
        break
      case 5:
        this.resetAccentPattern([true, false, false, false, false])
        break
      case 6:
        this.resetAccentPattern([true, false, false, false, false, false])
        break
      default: 
        this.resetAccentPattern([true, false, false, false])
    }
  }

  updateSubdivision(event) {
    let newsubdivision = event.target.selectedIndex
    this.setState({subdivision: newsubdivision})
  }

  play() {
    if (!metronome.config.unlocked) {
      // play silent buffer to unlock the audio
      var buffer = metronome.config.audioContext.createBuffer(1, 1, 22050)
      var node = metronome.config.audioContext.createBufferSource()
      node.buffer = buffer
      node.start(0)
      metronome.config.unlocked = true
    }
 
    this.resetAccentPattern([true, false, false, false])
    
  
    if (!this.state.isPlaying) { // pause audio
        metronome.config.current16thNote = 0
        metronome.config.nextNoteTime = metronome.config.audioContext.currentTime
        metronome.config.timerWorker.postMessage("start")
        this.setState({isPlaying: !this.state.isPlaying})
        return "stop"
    } else { // start audio
        metronome.config.timerWorker.postMessage("stop")
        this.setState({isPlaying: !this.state.isPlaying})
        console.log('you are here')
        return "play"
    }
  }
  
  render() {
    return (
      <div className="metronome">
        <button className="play" onClick={this.play}>{this.state.isPlaying ? 'stop' : 'play'}</button>
        <div>
          <div>Tempo: {this.state.BPM}</div>
          <div>BPM <input id="tempo" type="range" min="30.0" max="250.0" step="1" defaultValue={this.state.BPM} onChange={this.updateBPM}/></div>
        </div>
        <div>
          <span>Beat:</span>
          <select id="timeSignatureSelect" onChange={this.updateTimeSignature} defaultValue="4/4">
            <option>2/4</option>
            <option>3/4</option>
            <option>4/4</option>
            <option>5/4</option>
            <option>6/4</option>
          </select>
        </div>
        <div>
          <span>Subdivision:</span>
          <select onChange={this.updateSubdivision} defaultValue="Quarter notes">
            <option>Quarter notes</option>
            <option>8th notes</option>
            <option>16th notes</option>
            <option>Triplet notes</option>
          </select>
        </div>
      </div>
    )
  }
}

export default Metronome