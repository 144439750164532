import React, { Component } from 'react'
import './styles/Nav.css'

class Nav extends Component {

  render() {
    return (
      <div className="nav">
        <span>Metronome</span>
      </div>
    )
  }
}

export default Nav