import React, { Component } from 'react'
import './styles/Setlist.css'
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';

const SortableItem = SortableElement(({numBars, tempo, timeSignature, startPlaylist, subdivision, collectionIndex, playlistIndex, section}) => {
  return (
    <li className="playlist-item">
      <span>Section: {section}</span>
      <span>{numBars} bars at {tempo} BPM</span>
      <span>Time Signature: {timeSignature}</span>
      <span>Subdivision: {subdivision}</span>
      <button onClick={() => startPlaylist(collectionIndex, playlistIndex)}>Play</button>
    </li>
  );
});

const SortableList = SortableContainer(({items, collection, startPlaylist}) => {
  return (
    <ul className="playlist">
      {items.playlist.map((item, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          sortIndex={index}
          tempo={item.tempo}
          numBars={item.numBars}
          section={item.section}
          timeSignature={item.timeSignature}
          subdivision={item.subdivision}
          collection={collection}
          collectionIndex={collection}
          playlistIndex={index}
          startPlaylist={startPlaylist}
        />
      ))}
  </ul>
  )
});

class SetList extends Component {

  constructor(props) {
    super()
    this.state = {
     items: props.setlist
    }
    this.startPlaylist = props.startPlaylist
  }

  onSortEnd = ({oldIndex, newIndex, collection}) => {
    // const {items} = this.state.items[collection].playlist;
    var newPlaylist = this.state.items
    var newArray = arrayMove(this.state.items[collection].playlist, oldIndex, newIndex)
    newPlaylist[collection].playlist = newArray
    this.setState({
      items: newPlaylist,
    });
  };

  render() {
    const playlists = this.state.items.map((item, playlistIndex) => (
      <div key={`playlist-${playlistIndex}`} >
        <div className="playlist-title">{item.name}
          <button onClick={() => this.startPlaylist(playlistIndex, 0)}>Play</button>
        </div>
        <SortableList items={item} collection={playlistIndex} startPlaylist={this.startPlaylist} onSortEnd={this.onSortEnd}/>
      </div>
    ))

    return (
      <div className="setlist">{playlists}</div>
    )
  }
}

export default SetList