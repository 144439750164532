import React, { Component } from 'react';
import './App.css';
import Nav from './components/Nav'
import Setlist from './components/Setlist'
import Metronome from './components/Metronome'
import MetronomeOptions from './components/MetronomeOptions'

class App extends Component {
  constructor(props) {
    super()
    this.state = {
      accentPattern: [true, false, true, false],
      setlist: [
        {
          name: 'Opener',
          playlist: [
            {
              section: 'A',
              tempo: 180.0,
              numBars: 4,
              accentPattern: [true, false, false, false],
              timeSignature: 4,
              subdivision: 0
            },
            {
              section: 'A',
              tempo: 180.0,
              numBars: 2,
              accentPattern: [true, false, false],
              timeSignature: 3,
              subdivision: 0          
            },
            {
              section: 'B',
              tempo: 180.0,
              numBars: 4,
              accentPattern: [true, false, false, false],
              timeSignature: 4,
              subdivision: 0
            },
            {
              section: 'B',
              tempo: 180.0,
              numBars: 2,
              accentPattern: [true, false, false],
              timeSignature: 3,
              subdivision: 0
            },
            {
              section: 'C',
              tempo: 180.0,
              numBars: 8,
              accentPattern: [true, false, false, false],
              timeSignature: 4,
              subdivision: 0
            },
            {
              section: 'C',
              tempo: 180.0,
              numBars: 2,
              accentPattern: [true, false, false],
              timeSignature: 3,
              subdivision: 0
            }
          ]
        }
        // {
        //   name: 'Ballad',
        //   playlist: [
        //     {
        //       tempo: 160.0,
        //       numBars: 2,
        //       accentPattern: [true, false, false, false],
        //       timeSignature: 4,
        //       subdivision: 0
        //     },
        //     {
        //       tempo: 160.0,
        //       numBars: 3,
        //       accentPattern: [true, false, false, false],
        //       timeSignature: 4,
        //       subdivision: 0
        //     },
        //     {
        //       tempo: 164.0,
        //       numBars: 3,
        //       accentPattern: [true, false],
        //       timeSignature: 2,
        //       subdivision: 0
        //     },
        //     {
        //       tempo: 150.0,
        //       numBars: 3,
        //       accentPattern: [true, false, false, false],
        //       timeSignature: 4,
        //       subdivision: 0
        //     }
        //   ]
        // }
      ]
    }
    this.metRef = React.createRef()
    this.resetAccentPattern = this.resetAccentPattern.bind(this)
    this.startPlaylist = this.startPlaylist.bind(this)
  }

  updateAccentPattern(index) {
    let newAccentPattern = this.state.accentPattern;
    newAccentPattern[index] = !newAccentPattern[index]
    this.setState({accentPattern: newAccentPattern})
  }

  resetAccentPattern(newPattern) {
    console.log(`updating the pattern to ${newPattern}`)
    this.setState({accentPattern: newPattern})
  }

  startPlaylist(collectionIndex, playlistIndex) {
    this.metRef.current.startPlaylist(this.state.setlist[collectionIndex].playlist, playlistIndex) // send the playlist down
  }
  
  render() {
    return (
      <div className="App">
        <Nav></Nav>
        <Setlist
          setlist={this.state.setlist}
          startPlaylist={this.startPlaylist}>
        </Setlist>
        <Metronome
          ref={this.metRef}
          accentPattern={this.state.accentPattern} 
          resetAccentPattern={this.resetAccentPattern}>
        </Metronome>
        <MetronomeOptions
          accentPattern={this.state.accentPattern}
          updateAccentPattern={this.updateAccentPattern}>
        </MetronomeOptions>
      </div>
    );
  }
}

export default App;
