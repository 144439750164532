import React, { Component } from 'react'
import './styles/MetronomeOptions.css'

class MetronomeOptions extends Component {

  constructor(props) {
    super();
    this.state = {
      accentPattern: props.accentPattern
    }
    this.updateAccentPattern = props.updateAccentPattern
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.accentPattern !== this.state.accentPattern) {
      this.setState({accentPattern: nextProps.accentPattern})
    }
  }

  render() {
    const accentPattern = this.state.accentPattern.map((accent, index) => (
      <div onClick={() => this.updateAccentPattern(index)} key={index}>{accent ? '>' : '_'}</div>
    ))

    return (
      <div className="metro-options">
      <span>Accent Pattern</span>
        <div className="accent-pattern">{accentPattern}</div>
      </div>
    )
  }
}

export default MetronomeOptions 