let config ={
  audioContext: new AudioContext(),
  unlocked: false,
  current16thNote: null,    // What note is currently last scheduled?
  lookahead: 25.0,          // How frequently to call scheduling function (in milliseconds)
  scheduleAheadTime: 0.1,   // How far ahead to schedule audio (sec)
                            // This is calculated from lookahead, and overlaps 
                            // with next interval (in case the timer is late)
  nextNoteTime: 0.0,        // when the next note is due.
  noteLength: 0.05,         // length of "beep" (in seconds)
  accentPattern: [true, false, false, false], // false = no accent, true = accent // state
  notesInQueue: [],         // the notes that have been put into the web audio,
                            // and may or may not have played yet. {note, time}
  timerWorker: new Worker("metronomeworker.js")     // The Web Worker used to fire timer messages
} 

module.exports = {
  config
}